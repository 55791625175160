import { readonly } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import {
  GetStoryBySlugParams,
  GetStoryByUUIDParams,
  GetArticleBySubCategoryUUIDParams,
  GetArticleByCategoryUUIDParams,
  GetSelectedArticlesByUUIDsParams,
  GetSubCategoriesByParentFullSlugParams,
  GetMainCategoriesParams,
  GetStoresParams,
  GetWorkOffersParams,
  UseStoryblokViewInterface, GetBlogCategoriesParams, GetSelectedArticlesByOrderedUUIDsParams,
  GetDatasourceEntriesByNameParams,
} from './useStoryblokView';
import useStoryblok from '../useStoryblok';
import type { FilerQueryStoryParams } from '../useStoryblok';
import type { GetStoryblokApiStoryResult, GetStoryblokApiStoriesResult, GetStoryblokApiDatasourcesResult } from '../../helpers/fetch';

/**
 * Allows getting stories from Storyblok by slug, UUID, or filtering by query.
 *
 * See the {@link UseStoryblokViewInterface} for a list of methods and values available in this composable.
 */
export function useStoryblokView(): UseStoryblokViewInterface {
  const {
    getStories, getStory, getDatasourceEntries, resolveRichText, loading, error,
  } = useStoryblok();

  const getStoryBySlug = async (
    { slug, params = {}, redirectOnError = false }: GetStoryBySlugParams,
  ): Promise<GetStoryblokApiStoryResult | null> => {
    try {
      if (!slug) return null;

      return await getStory({
        type: 'slug', slug, params, redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getStoryBySlug', err);
    }
    return null;
  };

  const getStoryByUUID = async (
    { uuid, params = {}, redirectOnError = false }: GetStoryByUUIDParams,
  ): Promise<GetStoryblokApiStoryResult | null> => {
    try {
      if (!uuid) return null;
      const extendedParams = {
        find_by: 'uuid',
        ...params,
      };
      return await getStory({
        type: 'uuid', slug: uuid, params: extendedParams, redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getStoryByUUID', err);
    }
    return null;
  };

  const getArticleBySubCategoryUUID = async (
    { uuid, params = {}, redirectOnError = false }: GetArticleBySubCategoryUUIDParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      if (!uuid) return null;
      const key = 'filter_query[subCategories][any_in_array]' as string;
      return await getStories({
        type: 'filterQuery',
        params: {
          [key]: uuid,
          ...params,
        },
        redirectOnError,
      } as FilerQueryStoryParams);
    } catch (err) {
      Logger.error('useStoryblokView/getArticleBySubCategoryUUID', err);
    }
    return null;
  };

  const getArticleByCategoryUUID = async (
    { uuid, params = {}, redirectOnError = false }: GetArticleByCategoryUUIDParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      if (!uuid) return null;
      const extendedParams = { ...params };
      if (!params.search_term) {
        extendedParams.sort_by = 'content.wpPublishDate:desc';
      }
      const key = 'filter_query[mainCategories][any_in_array]' as string;
      return await getStories({
        type: 'filterQuery',
        params: {
          [key]: uuid,
          ...extendedParams,
        },
        redirectOnError,
      } as FilerQueryStoryParams);
    } catch (err) {
      Logger.error('useStoryblokView/getArticleByCategoryUUID', err);
    }
    return null;
  };

  const getSelectedArticlesByUUIDs = async (
    { uuids, params = {}, redirectOnError = false }: GetSelectedArticlesByUUIDsParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      if (!uuids || uuids.length === 0) return null;
      return await getStories({
        type: 'uuids',
        params: {
          by_uuids: uuids.join(','),
          ...params,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getSelectedArticlesByUUIDs', err);
    }
    return null;
  };

  const getSelectedArticlesByOrderedUUIDs = async (
    { uuids, params = {}, redirectOnError = false }: GetSelectedArticlesByOrderedUUIDsParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      if (!uuids || uuids.length === 0) return null;
      return await getStories({
        type: 'orderedUuids',
        params: {
          by_uuids_ordered: uuids.join(','),
          ...params,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getSelectedArticlesByOrderedUUIDs', err);
    }
    return null;
  };

  const getSubCategoriesByParentFullSlug = async (
    { fullSlug, params = {}, redirectOnError = false }: GetSubCategoriesByParentFullSlugParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      const extendedParams = { ...params };
      if (fullSlug) {
        extendedParams.by_slugs = `${fullSlug}*`;
      }

      const { stories: result, headers } = await getStories({
        type: 'contentType',
        params: {
          content_type: 'blogSubCategory',
          ...extendedParams,
        },
        redirectOnError,
      });

      const sortedResult = result.sort((a, b) => {
        const sortOrderA = a.content.sortOrder;
        const sortOrderB = b.content.sortOrder;
        if (sortOrderA === 0) return 1;
        if (sortOrderB === 0) return -1;
        return sortOrderA - sortOrderB;
      });

      return {
        stories: sortedResult,
        headers,
      };
    } catch (err) {
      Logger.error('useStoryblokView/getSubCategoriesByParentFullSlug', err);
    }
    return null;
  };

  const getMainCategories = async (
    { params = {}, redirectOnError = false }: GetMainCategoriesParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      return await getStories({
        type: 'contentType',
        params: {
          content_type: 'mainCategory',
          ...params,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getMainCategories', err);
    }
    return null;
  };

  const getBlogCategories = async (
    { params = {}, redirectOnError = false }: GetBlogCategoriesParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      return await getStories({
        type: 'contentType',
        params: {
          content_type: 'blogCategory',
          ...params,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getBlogCategories', err);
    }
    return null;
  };

  const getWorkOffers = async (
    { params = {}, redirectOnError = false }: GetWorkOffersParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      return await getStories({
        type: 'contentType',
        params: {
          content_type: 'ofertaPracy',
          ...params,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getWorkOffers', err);
    }
    return null;
  };

  const getStoresByParentFullSlug = async (
    { fullSlug, params = {}, redirectOnError = false }: GetStoresParams,
  ): Promise<GetStoryblokApiStoriesResult | null> => {
    try {
      const extendedParams = { ...params };
      if (fullSlug) {
        extendedParams.by_slugs = `${fullSlug}*`;
      }
      return await getStories({
        type: 'contentType',
        params: {
          content_type: 'sklepStacjonarny',
          ...extendedParams,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getStoresByParentFullSlug', err);
    }
    return null;
  };

  const getDatasourceEntriesByName = async (
    { name, params = {}, redirectOnError = false }: GetDatasourceEntriesByNameParams,
  ): Promise<GetStoryblokApiDatasourcesResult | null> => {
    try {
      if (!name) return null;
      const extendedParams = {
        ...params,
        datasource: name,
      };
      return await getDatasourceEntries({
        type: 'datasource',
        params: {
          ...extendedParams,
        },
        redirectOnError,
      });
    } catch (err) {
      Logger.error('useStoryblokView/getDatasourceEntriesByName', err);
    }
    return null;
  };

  return {
    loading: readonly(loading),
    error: readonly(error),
    resolveRichText,
    getStoryBySlug,
    getStoryByUUID,
    getArticleBySubCategoryUUID,
    getArticleByCategoryUUID,
    getSelectedArticlesByUUIDs,
    getSubCategoriesByParentFullSlug,
    getMainCategories,
    getBlogCategories,
    getSelectedArticlesByOrderedUUIDs,
    getWorkOffers,
    getStoresByParentFullSlug,
    getDatasourceEntriesByName,
  };
}

export * from './useStoryblokView';
export default useStoryblokView;
