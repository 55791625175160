/* eslint-disable unicorn/no-lonely-if */
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { ISbRichtext } from '@storyblok/js/dist/types';
import type {
  GetStoriesParams,
  GetStoryParams,
  GetDatasourceEntriesParams,
  UseStoryblokErrors,
  UseStoryblokInterface,
  ResolveRichTextParams,
} from './useStoryblok';
import { fetchStoryblokApi } from '../../helpers/fetch';
import { renderRichText } from '../../helpers/renderRichText';
import type { GetStoryblokApiStoryResult, GetStoryblokApiStoriesResult, GetStoryblokApiDatasourcesResult } from '../../helpers/fetch';

/**
 * Allows working with the Storyblok API.
 *
 * See the {@link UseStoryblokInterface} for a list of methods and values available in this composable.
 */
export function useStoryblok(): UseStoryblokInterface {
  const { error: contextError } = useContext();
  const loading = ref(false);
  const error = ref<UseStoryblokErrors>({
    getStoriesByUUID: null,
    getStoriesBySearchTerm: null,
    getStoryBySlug: null,
  });

  const getStories = async ({ type, params, redirectOnError = false }: GetStoriesParams): Promise<GetStoryblokApiStoriesResult | null> => {
    Logger.debug('useStoryblok.getStoriesByUUID', { type, params });

    let result = null;

    try {
      loading.value = true;

      result = await fetchStoryblokApi({ strategy: 'stories', params });

      error.value.getStoriesByUUID = null;
    } catch (err) {
      error.value.getStoriesByUUID = err;
      Logger.error('useStoryblok/getStories', err);

      if (redirectOnError) {
        const errorMessage = err.message;
        if (typeof errorMessage === 'string') {
          const parsedError = JSON.parse(errorMessage as string);
          contextError({ statusCode: parsedError.status ?? 500 });
        } else {
          contextError({ statusCode: 500 });
        }
      }
    } finally {
      loading.value = false;
    }

    return result;
  };

  const getDatasourceEntries = async ({ type, params, redirectOnError = false }: GetDatasourceEntriesParams):
  Promise<GetStoryblokApiDatasourcesResult | null> => {
    Logger.debug('useStoryblok.getDatasourceEntries', { type, params });

    let result = null;

    try {
      loading.value = true;

      result = await fetchStoryblokApi({ strategy: 'datasource', params });

      error.value.getStoriesByUUID = null;
    } catch (err) {
      error.value.getStoriesByUUID = err;
      Logger.error('useStoryblok/getDatasourceEntries', err);

      if (redirectOnError) {
        const errorMessage = err.message;
        if (typeof errorMessage === 'string') {
          const parsedError = JSON.parse(errorMessage as string);
          contextError({ statusCode: parsedError.status ?? 500 });
        } else {
          contextError({ statusCode: 500 });
        }
      }
    } finally {
      loading.value = false;
    }

    return result;
  };

  const getStory = async ({
    type, params, slug, redirectOnError = false,
  }: GetStoryParams): Promise<GetStoryblokApiStoryResult | null> => {
    Logger.debug('useStoryblok.getStoryBySlug', { type, params });

    let result = null;

    try {
      loading.value = true;

      result = await fetchStoryblokApi({ path: slug, strategy: 'story', params });

      error.value.getStoryBySlug = null;
    } catch (err) {
      error.value.getStoryBySlug = err;
      Logger.error('useStoryblok/getStoryBySlug', err);

      if (redirectOnError) {
        const errorMessage = err.message;
        if (typeof errorMessage === 'string') {
          const parsedError = JSON.parse(errorMessage as string);
          contextError({ statusCode: parsedError.status ?? 500 });
        } else {
          contextError({ statusCode: 500 });
        }
      }
    } finally {
      loading.value = false;
    }

    return result;
  };

  const resolveRichText = ({ text }: ResolveRichTextParams): ISbRichtext | null => {
    Logger.debug('useStoryblok.resolveRichText', { text });
    let result = null;

    try {
      result = renderRichText(text);
      error.value.getStoryBySlug = null;
    } catch (err) {
      error.value.getStoryBySlug = err;
      Logger.error('useStoryblok/getStoryBySlug', err);
    }

    return result;
  };

  return {
    getStories,
    getStory,
    getDatasourceEntries,
    resolveRichText,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useStoryblok';
export default useStoryblok;
